import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Inject } from '@syncfusion/ej2-react-richtexteditor';
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAuthToken } from '../helpers/authentication';
import { fieldRequired } from '../helpers/validation';
import '../helpers/date-prototypes';

export class Events extends Component {

  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
    ];

    this.state = {
      editData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      id: 0,
      name: '',
      startDate: new Date(),
      endDate: new Date(),
      status: 0,

    };
    this.toggle = this.toggle.bind(this);

  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  componentDidMount() {
    document.title = "Events Administration";
    this.loadData();
  }

  //static toSystemDate = function (value) {
  //  var mm = value.getMonth() + 1 // getMonth() is zero-based
  //  var dd = value.getDate()

  //  return [value.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('-')
  //}


  addItem = (ev) => { this.editItem(0); }

  editItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.id === id });
      console.log(data);
      this.setState({ id: data.id, name: data.name, startDate: data.startDate, endDate: data.endDate, status: data.status, });
    }
    else {
      //clear state fields
      this.setState({
        id: 0,
        name: '',
        startDate: new Date(),
        endDate: new Date(),
        status: 0,
      });
    }
    this.setState({ editId: id, editModal: true });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;
    valid &= fieldRequired(this.state.name, 'tbNameError', '* required');
    valid &= fieldRequired(this.state.startDate, 'tbStartDateError', '* required');
    valid &= fieldRequired(this.state.endDate, 'tbEndDateError', '* required');
    console.log("saveItem", this.state.startDate);
    if (valid) {
      const data = this.state.editData.find((item) => { return item.id === this.state.editId });
      if (this.state.editId > 0) { //do not overwrite the following fie when updating
        this.setState({
          
          id: data.id,

        });
      }
      else {
        this.setState({
          status: 1
        });
      }
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {

      case 'Delete':
        this.deleteItem(args.rowData.id);
        break;
      default: //edit
        this.editItem(args.rowData.id);
        break;
    }
  }

  static renderDataTable(data, gridCommands, gridToolbar, commandClick) {
    
    return (
      <GridComponent dataSource={data} ref={g => this.grid = g} allowFiltering={true} allowSorting={true} filterSettings={{ type: "Menu" }} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} >
        <ColumnsDirective>
          <ColumnDirective field='name' width='100' headerText="Name" />
          <ColumnDirective field='startDate' width='30' headerText="Start Date" type='dateTime' format="yyyy-MM-dd" />
          <ColumnDirective field='endDate' width='30' headerText="End Date" type='dateTime' format="yyyy-MM-dd" />
          <ColumnDirective headerText='Actions' width='20' commands={gridCommands} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>
    );
  }

  taskDate(dateMilli) {
    console.log(dateMilli);
    var d = (new Date(dateMilli) + '').split(' ');
    d[2] = d[2] + ',';

    return [d[0], d[1], d[2], d[3]].join(' ');
  }

  render() {
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : Events.renderDataTable(this.state.editData, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand);

    return (
      <>
        <div className="container">
          <Row>
            <Col xs={6}>
              <h1>Events Administration</h1>
            </Col>
            <Col xs={6} className="text-end align-self-center">
              <Button color="primary" size="sm" onClick={this.addItem}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>Edit Events</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className='mb-3 col-md-6'>
                <TextBoxComponent id='tbName' name='tbName' placeholder='Name' type='text' maxLength='250' floatLabelType='Auto' showClearButton={true} value={this.state.name} onChange={e => this.setState({ name: e.target.value })} /> <div id='tbNameError' className='error-message' />
              </div>

              <div className='mb-3 col-md-3'>
                <DatePickerComponent id='tbStartDate' name='tbStartDate' placeholder='Start Date' floatLabelType='Auto' showClearButton={true} format='yyyy-MM-dd' value={this.state.startDate} onChange={e => this.setState({ startDate: e.target.value.toSystemDate() })} /> <div id='tbStartDateError' className='error-message' />
              </div>

              <div className='mb-3 col-md-3'>
                <DatePickerComponent id='tbEndDate' name='tbEndDate' placeholder='End Date' floatLabelType='Auto' showClearButton={true} format='yyyy-MM-dd' value={this.state.endDate} onChange={e => this.setState({ endDate: e.target.value.toSystemDate() })} /> <div id='tbEndDateError' className='error-message' />
              </div>
            </div>


          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
      </>

    );
  }

  async loadData() {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/events', {
        method: 'GET',
        //withCredentials: true,
        //credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {

        const data = await response.json();
        
        this.setState({ editData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  async saveData(dataId) {

    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    console.log(this.state.startDate)
    var data = { Id: this.state.id, Name: this.state.name, StartDate: this.state.startDate, EndDate: this.state.endDate, Status: 1 }
    console.log(data)
    try {
      const response = await fetch('api/events', {
        method: dataId === 0 ? 'POST' : 'PUT',
        //withCredentials: true,
        //credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Events', content: 'The events was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Events', content: 'There was an error saving the events!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/events/' + dataId, {
        method: 'DELETE',
        //withCredentials: true,
        //credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Events', content: 'The events was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          this.props.history.push("/login");
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Events', content: 'There was an error deleting the events!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

