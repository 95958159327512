import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, ExcelExport, Filter, GridComponent, Inject, Page, Search, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import moment from 'moment';
import React, { Component } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { getAuthToken, IsAuthenticated } from '../helpers/authentication';
import ThrobbleHelper from '../helpers/ThrobbleHelper';
import { showToast } from '../helpers/ToastHelper';

export default class VisitsReport extends Component {

  static grid = null;
  constructor(props) {
    super(props);

    this.state = {
      VisitsData: [],
      allEventData: [],
      SupplierList: [],
      VisitorList: [],
      SupplierName: "",
      SupplierId: 0,
      VisitorId: 0,
      VisitorName: "",
      loading: true,
      gridToolbar: ['Search'],
      EventFilter: 0
    }
  }

  componentDidMount() {
    IsAuthenticated().then((res) => {
      if (res) {
        ThrobbleHelper.toggleThrobble(true, "Loading Report");
        this.loadData();
      }
    }).catch(() => window.location.href = "/login");
  }

  async loadData() {
    const bearer = "Bearer " + getAuthToken();
    try {

      const response = await fetch("/api/visitslog/GetVisitsReport", {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        this.setState({ VisitsData: data });
      } else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login"
      }
    } catch (e) {
      console.error("Visit Log:", e);
    }

    try {
      const VisitorReponse = await fetch("/api/visitors",
        {
          method: 'GET',
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json'
          }
        });

      if (VisitorReponse.ok) {
        const data = await VisitorReponse.json();
        const VisitorList = [{ id: 0, fullname: "- All Visitors -" }, ...(data.map(item => {
          return { id: item.id, fullname: item.firstName + " " + item.lastName };
        }))]
        this.setState({ VisitorList });
      }
    } catch (e) {
      console.error("Visitors: ", e);
    }

    try {
      const SupplierResponse = await fetch("/api/suppliers", {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });

      if (SupplierResponse.ok) {
        const data = await SupplierResponse.json();
        const SupplierList = [{ id: 0, name: "- All Suppliers -" }, ...data];
        this.setState({ SupplierList, loading: false });
        ThrobbleHelper.toggleThrobble(false);
      } else {
        console.log(SupplierResponse.status + ": " + SupplierResponse.statusText);
        if (SupplierResponse.status === 401)
          window.location.href = "/login";

        this.setState({ loading: false });
        ThrobbleHelper.toggleThrobble(false);
      }
    }
    catch (e) {
      console.error("Supplier:", e)
      this.setState({ loading: false });
    }

    try {
      const response = await fetch('api/events', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        data.splice(0, 0, {
          "id": 0,
          "name": "- All Events -",
          "startDate": "2020-05-01T12:05:50",
          "endDate": "2034-05-31T12:05:50",
          "status": 1
        });
        //console.log(data);
        this.setState({ allEventData: data, loading: false });
        ThrobbleHelper.toggleThrobble(false);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }

  }

  async toolbarClick(type) {

    let fileName;
    if (this.state.SupplierId > 0)
      fileName = this.state.CompetitionName.replace(/(\W)/g, "_") + "_" + moment().format("DD_MMMM_YYYY");
    else
      fileName = moment().format("DD_MMMM_YYYY");
    switch (type) {
      case "All":
        if (VisitsReport.grid) {
          ThrobbleHelper.toggleThrobble(true, "Exporting Excel Data")
          fileName += "_expo_visitors_report.xlsx";
          const exportProps = {
            fileName
          }
          VisitsReport.grid.excelExport(exportProps).then(res => {
            if (res.worksheets.length > 0) {
              showToast("success", "Excel Export", "Entries Data successfully exported");
            } else {
              showToast("error", "Excel Export", "There was an error exporting entries data");
            }
          });
        }
        break;
      default:
        return;
    }


  }

  renderReport(SupplierId, VisitorId, EventId, ReportData, gridToolbar) {
    if (SupplierId > 0)
      ReportData = ReportData.filter(c => c.supplierId === SupplierId);

    if (VisitorId > 0)
      ReportData = ReportData.filter(c => c.visitorId === VisitorId);

    if (EventId > 0)
      ReportData = ReportData.filter(c => c.eventId === EventId);

    ReportData.map(i => i.dateVisited = new Date(i.dateVisited));

    return (
      <GridComponent id="grid" dataSource={ReportData} allowExcelExport={true} allowFiltering={true} filterSettings={{ type: "Menu" }} allowPaging={true}
        allowSorting={true} sortSettings={{ columns: [{ field: "dateCaptured", direction: "Descending" }] }} pageSettings={{ pageSize: 25 }} ref={e => VisitsReport.grid = e} toolbar={gridToolbar} >
        <ColumnsDirective>
          <ColumnDirective field='visitorName' width={"200"} headerText='Visitor Name' textAlign='Left' />
          <ColumnDirective field='supplierName' width={"200"} headerText='Supplier' textAlign='Left' />
          
          <ColumnDirective field="dateVisited" width={"200"} headerText="Date and Time Visited" format={{ type: "dateTime", format: "dd MMMM yyyy HH:mm" }} textAlign='Left' />
        </ColumnsDirective>
        <Inject services={[Toolbar, ExcelExport, Filter, Page, Sort, Search]} />
      </GridComponent>
    )
  }

  render() {
    const content = this.state.loading ? <em></em> : this.renderReport(this.state.SupplierId, this.state.VisitorId, this.state.EventId, this.state.VisitsData, this.state.gridToolbar);
    return (
      <div className='container'>
        <div className="row">
          <div className="col-md-4 mb-3">
            <h1>Visits Reports</h1>
          </div>
          <div className="col-md-8 align-self-center text-end">
            <Button size='sm' className="btn-purple" outline onClick={() => this.toolbarClick("All")}><i className='fa fa-file-excel me-2'></i>Export Visits</Button>
            {/* <Button size='sm' className="btn-purple ms-2" outline onClick={() => this.toolbarClick("Completed")} hidden={this.state.SupplierId === 0}><i className='fa fa-file-excel me-2'></i>Export User Completed</Button> */}
          </div>
          <div className="col-12 mb-3">
            <Card>
              <CardBody>
                <div className="row">
                  <div className="col-md-4">
                    <label>Filter By Event</label>
                    <DropDownListComponent fields={{ value: "id", text: "name" }} placeholder="- All Events -" dataSource={this.state.allEventData} value={this.state.EventFilter} filterType='Contains' allowFiltering={true} select={e => this.setState({ EventFilter: e.itemData.id })} />
                  </div>
                  <div className="col-md-4">
                    <label>Filter By Supplier</label>
                    <DropDownListComponent fields={{ value: "id", text: "name" }} dataSource={this.state.SupplierList} value={this.state.SupplierId} filterType='Contains' allowFiltering={true} select={e => this.setState({ SupplierId: e.itemData.id, SupplierName: e.itemData.name })} />

                  </div>
                  <div className="col-md-4">
                    <label>Filter By Visitor</label>
                    <DropDownListComponent fields={{ value: "id", text: "fullname" }} dataSource={this.state.VisitorList} value={this.state.VisitorId} filterType='Contains' allowFiltering={true} select={e => this.setState({ VisitorId: e.itemData.id, VisitorName: e.itemData.name })} />

                  </div>
                </div>


              </CardBody>
            </Card>
          </div>
          <div className="col-12">
            {content}
          </div>
        </div>
      </div>
    )
  }
}
