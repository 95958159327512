import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import React, { Component } from "react";
import { Button, Input, Spinner } from "reactstrap";

import { GetProvinceList } from "../helpers/Lookup";
import ThrobbleHelper from "../helpers/ThrobbleHelper";
import { showToast } from "../helpers/ToastHelper";
import { fieldRequired, emailRequired } from "../helpers/validation";
import ReCAPTCHA from "react-google-recaptcha";
export default class Register extends Component {
  constructor(props) {
    super(props);
    //const recaptcha = useRef();
    this.state = {

      EditId: 0,
      Id: 0,
      UserId: "",
      UserType: 10,
      EventId: 0,
      FirstName: "",
      LastName: "",
      EmailAddress: "",
      Mobile: "",
      WorkNumber: "",
      Company: "",
      Designation: "",
      Province: 0,
      Password: "",
      QRImage: "",
      isPasswordValid: true,
      loading: true,
      eventData: [],
      captchaKey: ""
    };

    this.SaveData = this.SaveData.bind(this);
  }

  componentDidMount() {
    this.loadData();
    //ThrobbleHelper.toggleThrobble(true, "Loading Suppliers");
  }

  clearForm() {
    this.setState({
      FirstName: "",
      LastName: "",
      EmailAddress: "",
      Mobile: "",
      WorkNumber: "",
      Company: "",
      Designation: "",
      Province: 0,
      Password: "",
      QRImage: "",
      captchaKey: ""
    });
  }


  async loadData() {
    try {
      const response = await fetch('api/events/getactive', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          //'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ eventData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }

    
  }

  renderUserForm(state) {
    const ProvinceList = GetProvinceList();
    return (
      <>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label>First Name<span className="ErrorText ms-1">*</span></label>
            <Input type="text" bsSize={"sm"} value={state.FirstName} onChange={(e) => this.setState({ FirstName: e.target.value })} />
            <div id='tbFirstNameError' className='error-message' />
          </div>
          <div className="col-md-6 mb-3">
            <label>Last Name<span className="ErrorText ms-1">*</span>{" "}</label>
            <Input type="text" bsSize={"sm"} value={state.LastName} onChange={(e) => this.setState({ LastName: e.target.value })} />
            <div id='tbLastNameError' className='error-message' />
          </div>
          <div className="col-md-6 mb-3">
            <label>Email Address<span className="ErrorText ms-1">*</span></label>
            <Input type="email" bsSize={"sm"} value={state.EmailAddress} onChange={(e) => this.setState({ EmailAddress: e.target.value })} />
            <div id='tbEmailAddressError' className='error-message' />
          </div>
          <div className="col-md-3 mb-3">
            <label>Mobile<span className="ErrorText ms-1">*</span></label>
            <Input type="text" bsSize={"sm"} value={state.Mobile} onChange={(e) => this.setState({ Mobile: e.target.value })} />
            <div id='tbMobileError' className='error-message' />
          </div>
          <div className="col-md-3 mb-3">
            <label>Work Number</label>
            <Input type="text" bsSize={"sm"} value={state.WorkNumber} onChange={(e) => this.setState({ WorkNumber: e.target.value })} />
          </div>
          <div className="col-md-6 mb-3">
            <label>Company<span className="ErrorText ms-1">*</span></label>
            <Input type="text" bsSize={"sm"} value={state.Company} onChange={(e) => this.setState({ Company: e.target.value })} />
            <div id='tbCompanyError' className='error-message' />
          </div>
          <div className="col-md-6 mb-3">
            <label>Designation</label>
            <Input type="text" bsSize="sm" value={state.Designation} onChange={(e) => this.setState({ Designation: e.target.value })} />
          </div>
          <div className="col-md-6 mb-3">
            <label>Province<span className="ErrorText ms-1">*</span></label>
            <DropDownListComponent dataSource={ProvinceList} fields={{ text: "text", value: "value" }} value={state.Province} placeholder="- Select Province -" select={(e) => { this.setState({ Province: e.itemData.value }); }} />
            <div id='tbProvinceError' className='error-message' />
          </div>
          <div className="col-md-6 mb-3">
            <label>Event<span className="ErrorText ms-1">*</span></label>
            <DropDownListComponent dataSource={this.state.eventData} fields={{ text: "name", value: "id" }} value={this.state.EventId} placeholder="- Select Event -" select={(e) => this.setState({ EventId: e.itemData.id })} />
            <div id='tbEventError' className='error-message' />
          </div>
          <div className="col-12">
            <ReCAPTCHA sitekey="6LdR-dcpAAAAADEe1eROtxIZToFndiwaxC6R_vRt" onChange={(e) => this.setState({ captchaKey: e })} />
            <div id='captchaError' className='error-message' />
          </div>
        </div>

        <div className="text-end ">
          <Button size="sm" color="success" onClick={this.SaveData}><i className="far fa-check-circle me-2"></i> Save{" "}</Button>
        </div>
      </>
    );
  }

  async SaveData(evt) {
    evt.stopPropagation();
    console.log("Key:" + this.state.captchaKey);
    //const bearer = "Bearer " + getAuthToken();
    try {
      let isValid = true;
      isValid &= fieldRequired(this.state.FirstName, "tbFirstNameError", "Required");
      isValid &= fieldRequired(this.state.LastName, "tbLastNameError", "Required");
      isValid &= emailRequired(this.state.EmailAddress, "tbEmailAddressError", "Required");
      isValid &= fieldRequired(this.state.Mobile, "tbMobileError", "Required");
      isValid &= fieldRequired(this.state.Company, "tbCompanyError", "Required");
      isValid &= fieldRequired(this.state.Province, "tbProvinceError", "Required");
      isValid &= fieldRequired(this.state.EventId, "tbEventError", "Required");
      isValid &= fieldRequired(this.state.captchaKey, "captchaError", "Required");


      if (isValid) {
        ThrobbleHelper.toggleThrobble(true, "Saving registration...");
        let data = {
          Id: this.state.Id,
          EventId: this.state.EventId,
          FirstName: this.state.FirstName,
          LastName: this.state.LastName,
          EmailAddress: this.state.EmailAddress,
          Mobile: this.state.Mobile,
          WorkNumber: this.state.WorkNumber,
          Company: this.state.Company,
          Designation: this.state.Designation,
          Province: this.state.Province,
        };
        console.log(data)
        const response = await fetch("/api/registration", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (response.ok) {
          await response.json();
          showToast("success", "Visitor Details", "Visitor was successfully saved!");
          ThrobbleHelper.toggleThrobble(false);
          this.clearForm();
        } else {
          console.log(response.status + ": " + response.statusText);
          if (response.status === 409) {
            showToast("error", "Visitor Details", "You have already been registered for this event using email address <i>" + this.state.EmailAddress+"</i>");
          } else
            showToast("error", "Visitor Details", "There was an error saving visitor");
          ThrobbleHelper.toggleThrobble(false);
        }
      }
    } catch (e) {
      console.error(e);
      showToast("error", "Visitor Details", "There was an error saving visitor");
      ThrobbleHelper.toggleThrobble(false)
    }
  }

  render() {
    const content = this.state.loading ? (
      <em>
        <Spinner className="me-2" /> Loading Visitor data...{" "}
      </em>
    ) : (
      this.renderUserForm(this.state)
    );
    return (
      <>
        <div className="container">
          <div className="row my-4 border-bottom pb-3">
            <div className="col-md-6"><img src="/assets/EST-Africa-logo.png" className="img-fluid w-100" style={{ maxWidth: "200px" }} alt="EST Africa Registration" /></div>
            <div className="col-md-6 text-end">
              <h1>Visitor Registration</h1>
            </div>
          </div>
          <div className="mt-3">{content}</div>
        </div>

        <ThrobbleHelper
          showThrobble={false}
          throbbleMessage=""
        />

      </>
    );
  }
}
